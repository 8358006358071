<template>
  <b-card class="">
    <div class="mb-1 d-flex justify-content-between">
      <h3>Company Licenses</h3>
      <hwa-button
        icon="PlusIcon"
        label="New License"
        @onClick="showAddCompanyModal()"
      />

    </div>

    <div class="body__view__content">
      <!-- basic modal -->
      <b-modal
        id="create_company"
        :title="is_edit_mode ? 'Edit License' : 'New License'"
        hide-footer
        @close="hideModal"
      >
        <b-card-text>
          <validation-observer ref="simpleRules">
            <b-form>
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label="Company Name"
                    label-for="mc-company-name"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Name"
                      rules="required"
                    >
                      <b-form-input
                        id="mc-company-name"
                        v-model="company.name"
                        placeholder="Company name"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label="Company Address"
                    label-for="mc-address"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Address"
                      rules="required"
                    >
                      <b-form-input
                        id="mc-address-name"
                        v-model="company.address"
                        placeholder="address goes here"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label="NMLSR ID Number"
                    label-for="mc-nmlsr_id_no"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="NMLSR"
                      rules="required"
                    >
                      <b-form-input
                        id="mc-nmlsr_id_no"
                        v-model="company.nmlsr_id_no"
                        placeholder="########"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label="State"
                    label-for="mc-state"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="State"
                      rules="required"
                    >
                      <b-form-select
                        id="mc-state"
                        v-model="company.state"
                        :options="options"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label="State Licensee ID"
                    label-for="mc-state-licensee"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="State"
                      rules="required"
                    >
                      <b-form-input
                        id="mc-state-licensee"
                        v-model="company.state_license_id"
                        placeholder="########"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- submit and reset -->
                <b-col>
                  <div class="d-flex justify-content-between mt-3">
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      type="button"
                      variant="outline-secondary"
                      :class="is_company_change ? 'active-secondary' : ''"
                      @click="hideModal"
                    >
                      Cancel
                    </b-button>
                    <b-button
                      v-if="is_edit_mode"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="button"
                      variant="outline-danger"
                      class="mr-1"
                      @click.prevent="deleteCompany"
                    >
                      Delete
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="button"
                      :class="is_company_change === true ? 'active-success' : ''"
                      variant="outline-primary"
                      class="mr-1"
                      @click="is_edit_mode ? editCompany() : createCompany()"
                    >
                      {{ is_edit_mode ? 'Save Changes' : 'Save' }}
                    </b-button>

                  </div>

                </b-col>
              </b-row>
            </b-form>
          </validation-observer>

        </b-card-text>
      </b-modal>
    </div>

    <b-table
      small
      :fields="fields"
      :items="companies"
      responsive="sm"
      selectable
      select-mode="single"
      @row-clicked="handleRowClick"
    />
  </b-card>
</template>

<script>
import {
  BCard, BModal, BRow, BTable, BCol, BCardText, BFormSelect, BFormGroup, BFormInput, BForm, BButton,
} from 'bootstrap-vue'
import HwaButton from '@/layouts/components/HwaButton.vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import showToast from '@/mixins/showToast'

export default {
  components: {
    BCard,
    HwaButton,
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BCardText,
    BTable,
    BFormSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [showToast],
  data() {
    return {
      fields: [{ key: 'name', label: 'Company Name', sortable: true }, { key: 'nmlsr_id_no', label: 'NMLSR ID NO', sortable: true }, { key: 'state', label: 'State', sortable: true }, { key: 'state_license_id', label: 'State Licensee', sortable: true }],
      name: '',
      required,
      options: [
        { value: 'VA', label: 'Viriginia' },
        { value: 'MD', label: 'Maryland' },
        { value: 'DC', label: 'Washington DC' },
      ],
      company: {
        name: 'Mortgage Envy Corporation',
        address: '',
        nmlsr_id_no: '',
        state: '',
        state_license_id: '',
      },
      companies: [],
      agents: [],
      is_edit_mode: false,
      is_company_change: false,
    }
  },
  watch: {
    company: {
      handler() {
        this.is_company_change = true
      },
      deep: true,
    },
  },
  mounted() {
    this.getAgents()
    this.getCompanies()
  },
  methods: {
    showAddCompanyModal() {
      this.$bvModal.show('create_company')
      this.is_company_change = false
    },
    createCompany() {
      this.is_company_change = false
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          this.$http.post('/api/companies', this.company).then(res => {
            this.showSuccess('company created succesfully')
            this.companies.push(res.data)
            this.$bvModal.hide('create_company')
          }).catch(err => {
            this.showError('Failed to save company')
          })
        }
      })
    },
    deleteCompany() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$http.delete(`/api/companies/${this.company.id}`).then(res => {
            this.showSuccess('company delete succesfully')
            this.getCompanies()
            this.hideModal()
          }).catch(err => {
            this.showError('Failed to delete company')
          })
        }
      })
    },
    editCompany() {
      this.$http.put(`/api/companies/${this.company.id}`, this.company).then(res => {
        this.showSuccess('company updated succesfully')
        this.getCompanies()
        this.$bvModal.hide('create_company')
        this.is_company_change = false
        this.emptyData()
      }).catch(err => {
        this.showError('Failed to update company')
      })
    },
    hideModal(e) {
      if (this.is_company_change) {
        e.preventDefault()
        this.$swal({
          title: 'Do you want to save changes?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Save changes!',
          cancelButtonText: 'Discard Changes',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.is_edit_mode = false
            this.is_company_change = false
            this.editCompany()
          } else if (result.dismiss === 'cancel') {
            this.is_edit_mode = false
            this.$bvModal.hide('create_company')
            this.is_company_change = false
            this.emptyData()
          }
        })
      } else {
        this.is_edit_mode = false
        this.$bvModal.hide('create_company')
        this.emptyData()
      }
    },
    emptyData() {
      this.company = {
        name: '',
        address: '',
        nmlsr_id_no: '',
        state_license_id: '',
      }
      const dom = this
      setTimeout(() => {
        dom.is_agent_change = false
      }, 100)
    },
    handleRowClick(item) {
      this.is_edit_mode = true
      this.company = item
      this.$bvModal.show('create_company')
      const dom = this
      setTimeout(() => {
        dom.is_company_change = false
      }, 100)
    },
    getAgents() {
      this.$http.get('/api/agents')
        .then(res => {
          this.agents = res.data
          res.data.forEach(item => this.options.push({ value: item.id, text: item.full_name }))
        })
        .catch(err => {
          this.showError('Failed to retrieve agents')
        })
    },
    getCompanies() {
      this.$http.get('/api/companies')
        .then(res => {
          this.companies = res.data
        })
        .catch(err => {
          this.showError('Failed to retrieve companies')
        })
    },

  },
}
</script>

<style lang="scss" scoped>

</style>
