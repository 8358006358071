var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{},[_c('div',{staticClass:"mb-1 d-flex justify-content-between"},[_c('h3',[_vm._v("Company Licenses")]),_c('hwa-button',{attrs:{"icon":"PlusIcon","label":"New License"},on:{"onClick":function($event){return _vm.showAddCompanyModal()}}})],1),_c('div',{staticClass:"body__view__content"},[_c('b-modal',{attrs:{"id":"create_company","title":_vm.is_edit_mode ? 'Edit License' : 'New License',"hide-footer":""},on:{"close":_vm.hideModal}},[_c('b-card-text',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Company Name","label-for":"mc-company-name"}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"mc-company-name","placeholder":"Company name","state":errors.length > 0 ? false:null},model:{value:(_vm.company.name),callback:function ($$v) {_vm.$set(_vm.company, "name", $$v)},expression:"company.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Company Address","label-for":"mc-address"}},[_c('validation-provider',{attrs:{"name":"Address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"mc-address-name","placeholder":"address goes here","state":errors.length > 0 ? false:null},model:{value:(_vm.company.address),callback:function ($$v) {_vm.$set(_vm.company, "address", $$v)},expression:"company.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"NMLSR ID Number","label-for":"mc-nmlsr_id_no"}},[_c('validation-provider',{attrs:{"name":"NMLSR","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"mc-nmlsr_id_no","placeholder":"########","state":errors.length > 0 ? false:null},model:{value:(_vm.company.nmlsr_id_no),callback:function ($$v) {_vm.$set(_vm.company, "nmlsr_id_no", $$v)},expression:"company.nmlsr_id_no"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"State","label-for":"mc-state"}},[_c('validation-provider',{attrs:{"name":"State","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"mc-state","options":_vm.options,"state":errors.length > 0 ? false:null},model:{value:(_vm.company.state),callback:function ($$v) {_vm.$set(_vm.company, "state", $$v)},expression:"company.state"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"State Licensee ID","label-for":"mc-state-licensee"}},[_c('validation-provider',{attrs:{"name":"State","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"mc-state-licensee","placeholder":"########","state":errors.length > 0 ? false:null},model:{value:(_vm.company.state_license_id),callback:function ($$v) {_vm.$set(_vm.company, "state_license_id", $$v)},expression:"company.state_license_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',[_c('div',{staticClass:"d-flex justify-content-between mt-3"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],class:_vm.is_company_change ? 'active-secondary' : '',attrs:{"type":"button","variant":"outline-secondary"},on:{"click":_vm.hideModal}},[_vm._v(" Cancel ")]),(_vm.is_edit_mode)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"button","variant":"outline-danger"},on:{"click":function($event){$event.preventDefault();return _vm.deleteCompany($event)}}},[_vm._v(" Delete ")]):_vm._e(),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",class:_vm.is_company_change === true ? 'active-success' : '',attrs:{"type":"button","variant":"outline-primary"},on:{"click":function($event){_vm.is_edit_mode ? _vm.editCompany() : _vm.createCompany()}}},[_vm._v(" "+_vm._s(_vm.is_edit_mode ? 'Save Changes' : 'Save')+" ")])],1)])],1)],1)],1)],1)],1)],1),_c('b-table',{attrs:{"small":"","fields":_vm.fields,"items":_vm.companies,"responsive":"sm","selectable":"","select-mode":"single"},on:{"row-clicked":_vm.handleRowClick}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }